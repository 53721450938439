import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { LaTeX } from "latex2react"
import cx from "classnames"

const SectionContent = ({ pageContext }) => {
  const { book, section, content, next, nextName, prev, prevName } = pageContext
  const Nav = () => {
    return (
      <div className="btn-toolbar">
        <div className="btn-group">
          <a
            className={cx("btn", "btn-small", { disabled: !prevName })}
            href={prev}
          >
            <i className="icon-step-backward"></i>
            {prevName}
          </a>
          <a
            className={cx("btn", "btn-small", { disabled: !nextName })}
            href={next}
          >
            {nextName}
            <i className="icon-step-forward"></i>
          </a>
        </div>
      </div>
    )
  }
  return (
    <Layout>
      <ul className="breadcrumb">
        <li>
          <a href={`/books/${book.id}`}>{book.name}</a>{" "}
          <span className="divider">/</span>
        </li>
        <li>
          <a href={`/books/${book.id}/sections/${section.id}`}>
            {section.name}
          </a>{" "}
          <span className="divider">/</span>
        </li>
        <li className="active">{content.name}</li>
      </ul>

      <Nav />

      <hr />

      <h3>{content.name}</h3>
      <LaTeX content={content.text} />
      <hr />
      <Nav />
    </Layout>
  )
}

export default SectionContent
